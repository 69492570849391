// extracted by mini-css-extract-plugin
export var header = "T_b";
export var item = "T_b6";
export var itemImage = "T_cM";
export var itemIsExternal = "T_cR";
export var itemIsInView = "T_cP";
export var itemIsLinked = "T_cQ";
export var itemText = "T_cN";
export var itemTime = "T_cS";
export var itemTimeIsBig = "T_cT";
export var logo = "T_bD";
export var root = "T_r";